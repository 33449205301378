<script>
export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card mini-stats-wid">
    <div class="card-body">
      <div class="media">
        <div class="media-body d-flex justify-content-between">
          <div>
            <p class="text-muted font-weight-medium mb-0">{{ t(title) }}</p>
          </div>
          <div>
            <h5 class="mb-0 notranslate">{{ t(value) }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>